export default ({
  variables,
  loading,
  // error,
  addToast,
  mutation,
  // mutationName,
}) => {
  if (variables.data && !loading) {
    mutation({
      variables,
    }).then(() => {
      addToast('Device Updated!', {
        appearance: 'success',
        autoDismiss: true,
      })
    })
  }
}
