import { gql } from '@apollo/client'
import { User, Template, Device, FileTemplates, Field } from './_fragments'

// REMOTE
export const CONENCTION_QUERY = gql`
  query connection {
    connection
  }
`

export const AUTH_QUERY = gql`
  query authenticate {
    _checkAuth
  }
`

export const CURRENT_USER_QUERY = gql`
  query currentUser {
    currentUser {
      ...UserBasicData
    }
  }
  ${User.fragments.UserBasicData}
`

export const GET_DEFAULT_TEMPLATE_QUERY = gql`
  query getDefaultTemplate {
    getDefaultTemplate {
      ...TemplateData
    }
  }
  ${Template.fragments.TemplateData}
`

export const GET_ALL_TEMPLATE_FIELDS_QUERY = gql`
  query getAllDefaultTemplateFields {
    getAllDefaultTemplateFields {
      ...FieldData
    }
  }
  ${Field.fragments.FieldData}
`
export const GET_DEVICES_QUERY = gql`
  query getDevices {
    getDevices {
      ...DeviceListData
    }
  }
  ${Device.fragments.DeviceListData}
`

export const GET_DEVICE_BY_ID_QUERY = gql`
  query getDeviceById($id: ID!) {
    getDeviceById(id: $id) {
      ...DeviceData
    }
  }
  ${Device.fragments.DeviceData}
`

export const GET_NEW_EMPTY_DEVICE_QUERY = gql`
  query getNewEmptyDevice {
    getNewEmptyDevice {
      ...NewDeviceData
    }
  }
  ${Device.fragments.NewDeviceData}
`

export const GET_FILE_TEMPLATES_QUERY = gql`
  query getFileTemplates {
    getFileTemplates {
      ...FileTemplatesData
    }
  }
  ${FileTemplates.fragments.FileTemplatesData}
`

export const GET_FILE_TEMPLATE_BY_QUERY = gql`
  query getFileTemplateByType($type: ConfigurationType!) {
    getFileTemplateByType(type: $type) {
      ...FileTemplatesData
    }
  }
  ${FileTemplates.fragments.FileTemplatesData}
`

export const GET_DEVICES_NAME_QUERY = gql`
  query getDevicesName {
    getDevicesName
  }
`
