import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { getQueryDefinition } from '@apollo/client/utilities'
import { useToasts } from 'react-toast-notifications'
import {
  ADD_FIELD_TO_DEFAULT_TEMPLATE_MUTATION,
  GET_DEFAULT_TEMPLATE_QUERY,
  GET_DEVICES_QUERY,
  GET_DEVICE_BY_ID_QUERY,
  UPDATE_DEFAULT_TEMPLATE_MUTATION,
} from '../api'
import { TemplateView } from '../components'

export default () => {
  const [data, setData] = React.useState()
  const firstRender = React.useRef(0)
  const { addToast } = useToasts()
  const { data: { getDefaultTemplate } = {}, loading } = useQuery(
    GET_DEFAULT_TEMPLATE_QUERY,
  )
  const [updateTemplate] = useMutation(UPDATE_DEFAULT_TEMPLATE_MUTATION)
  const [addTemplateField] = useMutation(ADD_FIELD_TO_DEFAULT_TEMPLATE_MUTATION)
  const update = (fields, cb) => {
    if (fields) {
      if (typeof fields === 'string') {
        const name = fields
        return addTemplateField({
          variables: {
            name,
          },
          refetchQueries: [{ query: GET_DEFAULT_TEMPLATE_QUERY }],
          update(cache, mutationResult) {
            const {
              data: { updateDefaultTemplate: object = {} } = {},
            } = mutationResult
            const queryName = getQueryDefinition(GET_DEVICE_BY_ID_QUERY)?.name
              ?.value
            const outstandingQueries = Object.entries(
              cache.data.data.ROOT_QUERY,
            )
              .filter(([key]) => key.includes(queryName))
              .map(([_, { __ref }]) => {
                const [__typename, _id] = __ref.split(':')
                return {
                  __typename,
                  _id,
                }
              })
            outstandingQueries.forEach(function(obj) {
              cache.modify({
                id: cache.identify(obj),
                fields: {
                  fields(existingRefs) {
                    existingRefs.forEach(function(item) {
                      const { __ref: ref } = item
                      const [__typename, _id] = ref.split(':')
                      const o = {
                        __typename,
                        _id,
                      }
                      cache.modify({
                        id: cache.identify(o),
                        fields: {
                          _id(_, { INVALIDATE }) {
                            return INVALIDATE
                          },
                        },
                      })
                    })
                  },
                },
              })
            })
            firstRender.current = firstRender.current + 1
            cb()
          },
        }).then(() => {
          addToast('Field added to the Default Template!', {
            appearance: 'success',
            autoDismiss: true,
          })
        })
      }
      return updateTemplate({
        variables: {
          data: {
            fields: fields.map(
              ({
                identifier,
                type,
                values = [],
                label,
                order,
                allowUserInput,
                endpoint,
                field,
              }) => ({
                identifier,
                type,
                values,
                label,
                order,
                allowUserInput,
                endpoint,
                field,
              }),
            ),
          },
        },
        refetchQueries: [
          { query: GET_DEFAULT_TEMPLATE_QUERY },
          { query: GET_DEVICES_QUERY },
        ],
        update(cache, mutationResult) {
          const {
            data: { updateDefaultTemplate: object = {} } = {},
          } = mutationResult
          const queryName = getQueryDefinition(GET_DEVICE_BY_ID_QUERY)?.name
            ?.value
          const outstandingQueries = Object.entries(cache.data.data.ROOT_QUERY)
            .filter(([key]) => key.includes(queryName))
            .map(([_, { __ref }]) => {
              const [__typename, _id] = __ref.split(':')
              return {
                __typename,
                _id,
              }
            })
          outstandingQueries.forEach(function(obj) {
            cache.modify({
              id: cache.identify(obj),
              fields: {
                fields(existingRefs) {
                  return existingRefs.map(item => {
                    const { __ref: ref } = item
                    const [__typename, _id] = ref.split(':')
                    const o = {
                      __typename,
                      _id,
                    }
                    cache.modify({
                      id: cache.identify(o),
                      fields: {
                        templateField(objRef, { readField }) {
                          const identifier = readField('identifier', objRef)
                          const match = object.fields.filter(
                            tf => tf.identifier === identifier,
                          )
                          if (match && match.length) return match[0]
                          return objRef
                        },
                      },
                    })
                    return item
                  })
                },
              },
            })
          })
          // firstRender.current = firstRender.current + 1
        },
      }).then(() =>
        addToast('Default Template Updated!', {
          appearance: 'success',
          autoDismiss: true,
        }),
      )
    }
  }
  React.useEffect(() => {
    if (
      !loading &&
      getDefaultTemplate?.fields?.length !== data?.fields?.length
    ) {
      const transformedData = {
        ...getDefaultTemplate,
        fields: getDefaultTemplate.fields
          .slice()
          .sort((a, b) => a.order - b.order),
      }
      firstRender.current = 1
      setData(transformedData)
    }
  }, [getDefaultTemplate])

  if (loading) return null
  return (
    <div>
      <TemplateView data={data} key={firstRender.current} update={update} />
    </div>
  )
}
