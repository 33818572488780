import * as React from 'react'

export default () => {
  return (
    <div className="flex justify-center items-center h-96">
      <div className="pb-8">
        <h2 className="text-2xl">File Zipped</h2>
      </div>
    </div>
  )
}
