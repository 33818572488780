import * as React from 'react'

export default () => {
  const htmlElRef = React.useRef(null)
  const setFocus = () => {
    setTimeout(() => htmlElRef?.current.focus(), 0)
  }

  return [htmlElRef, setFocus]
}
