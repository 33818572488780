import { getMainDefinition } from '@apollo/client/utilities'
import { useToasts } from 'react-toast-notifications'
import { useMutation } from '@apollo/client'

// import mutations
import {
  DELETE_DEVICE_MUTATION,
  UPDATE_DEVICE_MUTATION,
  SAVE_REVISION_MUTATION,
  SAVE_SEAL_MUTATION,
} from '../../api'

// import actions
import remove from './_remove'
import save from './_save'
import saveRevisionAction from './_saveRevision'
import saveSealAction from './_saveSeal'

const getMutationName = mutation => getMainDefinition(mutation)?.name?.value

export default () => {
  const { addToast } = useToasts()
  const [deleteDevice] = useMutation(DELETE_DEVICE_MUTATION)
  const [saveDevice, { loading: savingDevice, errorDevice }] = useMutation(
    UPDATE_DEVICE_MUTATION,
  )
  const [
    saveRevision,
    { loading: savingRevision, errorRevision },
  ] = useMutation(SAVE_REVISION_MUTATION)

  const [saveSeal, { loading: savingSeal, errorSeal }] = useMutation(
    SAVE_SEAL_MUTATION,
  )

  return {
    remove: args =>
      remove({
        variables: args?.variables,
        addToast,
        mutation: deleteDevice,
        mutationName: getMutationName(DELETE_DEVICE_MUTATION),
      }),
    save: args =>
      save({
        variables: args?.variables,
        loading: savingDevice,
        error: errorDevice,
        addToast,
        mutation: saveDevice,
        mutationName: getMutationName(UPDATE_DEVICE_MUTATION),
      }),
    saveRevision: args => {
      const { variables, ...rest } = args || {}
      return saveRevisionAction({
        variables: variables,
        args: rest,
        loading: savingRevision,
        error: errorRevision,
        addToast,
        mutation: saveRevision,
        mutationName: getMutationName(SAVE_REVISION_MUTATION),
      })
    },
    saveSeal: args => {
      const { variables, ...rest } = args || {}
      return saveSealAction({
        variables: variables,
        args: rest,
        loading: savingSeal,
        error: errorSeal,
        addToast,
        mutation: saveSeal,
        mutationName: getMutationName(SAVE_SEAL_MUTATION),
      })
    },
  }
}
