import * as React from 'react'
import TagsInput from './_rtl'

export default ({ item = {}, updateItem = () => {} }) => {
  const selectedTags = tags => {
    updateItem({
      ...item,
      values: tags,
    })
  }
  return (
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5 w-full">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Selection values
        </label>

        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg flex rounded-md">
            <TagsInput selectedTags={selectedTags} tags={item.values || []} />
          </div>
        </div>
      </div>
    </div>
  )
}
