import * as React from 'react'
import { useQuery } from '@apollo/client'
import { GET_DEVICES_QUERY } from '../api'
import { DeviceListView } from '../components'
import history from '../history'

export default () => {
  const { data: { getDevices: data = [] } = {}, loading } = useQuery(
    GET_DEVICES_QUERY,
  )

  const addDevice = () => history.push('/app/edit/device/')

  return (
    <div>
      <DeviceListView loading={loading} data={data} addDevice={addDevice} />
    </div>
  )
}
