import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'
import { GET_FILE_TEMPLATES_QUERY } from '../api'
import CodeEditor from './_configuration-file-container'
import fileTemplateType from '../settings/enums/_file-template-type.json'

export default () => {
  const [fileList, setFileList] = useState([])
  const { data: { getFileTemplates } = {}, loading } = useQuery(
    GET_FILE_TEMPLATES_QUERY,
  )

  const tabs = Object.entries(fileTemplateType).map(([k, v]) => ({
    id: k,
    label: `config-${v}`,
  }))

  const [selectedTab, setSelectedTab] = React.useState(tabs[0])

  useEffect(() => {
    if (typeof getFileTemplates !== 'undefined') {
      setFileList(getFileTemplates)
    }
  }, [getFileTemplates])
  console.log(fileList)
  var renderLinks = function() {
    if (fileList.length) {
      return (
        <div>
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
            >
              {tabs.map(tab => (
                <option key={tab.id}>{tab.label}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block bg-gray-300 py-4 mb-8 pl-4">
            <nav className="flex space-x-4" aria-label="Tabs">
              {tabs.map((tab, idx) => (
                <div
                  key={tab.id}
                  onClick={() => setSelectedTab(tab)}
                  className={`${
                    selectedTab && tab.id === selectedTab.id
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'text-gray-500 hover:text-gray-700 '
                  }  px-3 py-2 font-medium text-sm rounded-md cursor-pointer`}
                >
                  {tab.label}
                </div>
              ))}
            </nav>
          </div>
          <CodeEditor key={selectedTab.id} configType={selectedTab.id} />
        </div>
      )
      // return fileList.map(item => {
      //   return (
      //     <Link
      //       key={item._id}
      //       to={`/app/configuration-files/${item.configurationType}`}
      //       className="border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
      //     >
      //       File config-{fileTemplateType[item.configurationType]}
      //     </Link>
      //   )
      // })
    }

    return null
  }

  return <div>{renderLinks()}</div>
}
