import * as React from 'react'
import { PageWrapper } from '../components'
import { ConfigurationFileConatiner } from '../containers'

export default (props) => {
  return (
    <PageWrapper title="Configuration file">
      <ConfigurationFileConatiner {...props} />
    </PageWrapper>
  )
}
