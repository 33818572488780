import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import templateFieldType from '../../settings/enums/_template-field-type.json'
import externaApis from '../../settings/enums/_external-api-endpoints.json'
import TagsInput from './_select-field'
import { useModalHooks, useFocus } from '../../hooks'
import { ActionButton } from '../'
import getEnums from '../../settings/enums'

const TYPES = getEnums(templateFieldType, 'reference')
const APIS = getEnums(externaApis, 'entries')

const transformData = (fields, items) =>
  Object.entries(fields)?.map(([key, value]) => ({
    ...items.find(item => item.identifier.toString() === key.toString()),
    label: value,
  }))

// fake data generator
// const getItems = count =>
//   Array.from({ length: count }, (v, k) => k).map(k => ({
//     id: `item-${k}`,
//     content: `item ${k}`,
//   }))

// a little function to help us with reordering the result
const reorder = (list, startIndex = 0, endIndex = 0) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const grid = 8

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  // background: isDragging ? '#ccc' : 'white',
  ...(isDragging && { border: '1px dashed #c4c4c4' }),

  // styles we need to apply on draggables
  ...draggableStyle,
})

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? 'lightblue' : 'white',
  padding: grid,
  width: '100%',
})

export default ({ data = { fields: [] }, update = () => {} }) => {
  const { confirmationModal } = useModalHooks()
  const { handleSubmit, register, reset } = useForm()
  const renderKey = React.useRef(0)
  const [items, setItems] = React.useState(data.fields)
  const [showActionBar, setShowActionBar] = React.useState(false)
  const bottomRef = React.useRef()
  const topRef = React.useRef()

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const scrollToTop = () => {
    topRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    ).map((item, index) => ({ ...item, order: index }))

    setItems(reorderedItems)
  }

  const updateItem = (updatedItem, options) => {
    const { deleted = false } = options || {}
    let newItems
    if (deleted) {
      newItems = items.filter(
        item => item.identifier !== updatedItem.identifier,
      )
    } else
      newItems = items.map(item => {
        if (item.identifier === updatedItem.identifier) {
          return updatedItem
        }
        return item
      })
    setItems(newItems)
  }

  const onSubmit = fields => {
    const transformedData = transformData(fields, items)
    // TODO: [rico][conditional checks]: it will need a more robust test based on field identifiers
    if (items.length < data.fields?.length) {
      // one or more fields have been removed
      const removedFields = data.fields
        .filter(item => !items.find(it => it.identifier === item.identifier))
        .map(field => field.label)
      const message = (
        <div>
          <p className="text-sm text-gray-500 pb-2">
            This update contains the following removed fields that will also
            delete the corresponding fields in all devices
          </p>
          <p className="text-sm text-gray-500 pb-2">
            {removedFields.map(field => (
              <p key={field}>- {field}</p>
            ))}
          </p>
          <p className="text-sm font-bold text-gray-500 pb-2">
            This action cannot be undone.
          </p>
        </div>
      )
      confirmationModal({
        message,
        onConfirm: () => update(transformedData),
      })
    } else update(transformedData)
  }

  return (
    <div>
      <AddNewInputField
        scroll={scrollToBottom}
        update={update}
        confirmationModal={confirmationModal}
      />
      <div ref={topRef} />
      <form
        onMouseUp={() => {
          if (!showActionBar) setShowActionBar(true)
        }}
        onSubmit={handleSubmit(onSubmit)}
        className="space-y-8 divide-y divide-gray-200"
      >
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Edit
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Reorder the fields (Drag&Drop) and/or change their values
              </p>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {items?.map((item, index) => (
                      <Draggable
                        key={item._id}
                        draggableId={item._id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                            )}
                          >
                            <InputSelector
                              item={item}
                              register={register}
                              updateItem={updateItem}
                              renderKey={renderKey}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div
            className={`transition-opacity ${
              showActionBar ? 'opacity-100 ease-in duration-600' : 'opacity-0'
            } w-48 fixed bottom-10 rigth-30 flex justify-center items-center`}
          >
            <div className="w-full  bg-gray-400 flex justify-center py-2 rounded-lg bg-opacity-30">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => {
                  reset()
                  setItems(data.fields)
                  renderKey.current = renderKey.current + 1
                }}
              >
                Reset
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <ActionButton
            modifier={'☝'}
            backgroundColor="bg-indigo-400"
            actionFn={scrollToTop}
          />
        </div>
        <div ref={bottomRef} className="list-bottom pb-24" />
      </form>
    </div>
  )
}

const TypeSelect = ({ item, updateItem }) => (
  <span className="relative z-0 inline-flex shadow-sm rounded-md">
    {Object.entries(templateFieldType).map(([key, value], index) => (
      <button
        onClick={() =>
          updateItem({
            ...item,
            type: key,
            ...(key === TYPES.TEXT && { allowUserInput: false }),
          })
        }
        type="button"
        className={`relative inline-flex items-center px-4 py-2 ${
          index === 0
            ? 'rounded-l-md border'
            : index === Object.values(templateFieldType).length - 1
            ? 'rounded-r-md border'
            : 'border-t border-b'
        } border-gray-300 ${
          value === templateFieldType[item.type] ? 'bg-gray-300' : 'bg-white'
        } text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
      >
        {value}
      </button>
    ))}
  </span>
)

const Checkbox = ({ item, updateItem = () => {} }) => {
  const checked = item.allowUserInput
  return (
    <div className={'flex'}>
      <button
        onClick={() =>
          updateItem({
            ...item,
            allowUserInput: !item.allowUserInput,
          })
        }
        type="button"
        aria-pressed={checked}
        className={`${
          checked ? 'bg-indigo-600' : 'bg-gray-200'
        } mr-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        <span className="sr-only">Allow user input</span>
        <span
          className={`${
            checked ? 'translate-x-5' : 'translate-x-0'
          } relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
        >
          <span
            className={`${
              checked
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-200'
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-gray-400"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={`${
              checked
                ? 'opacity-100 ease-in duration-200'
                : 'opacity-0 ease-out duration-100'
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </button>
      <span>Allow user's input</span>
    </div>
  )
}

const Delete = ({ updateItem }) => {
  return (
    <div
      className="cursor-pointer group flex items-baseline px-2 py-2 text-base leading-5 font-medium rounded-md"
      aria-current="delete"
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        updateItem()
      }}
    >
      <svg
        className="text-red-300 w-6 h-6 hover:text-red-700"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
        />
      </svg>
    </div>
  )
}

const InputSelector = ({ item, register, updateItem, renderKey }) => {
  if (!item) return null
  const { type } = item
  if (type === TYPES.SELECT)
    return (
      <div className="flex items-baseline pb-8">
        <div className="w-full">
          <TextInput item={item} register={register} />
          <TagsInput
            key={renderKey.current}
            item={item}
            register={register}
            updateItem={updateItem}
          />
          <Checkbox item={item} updateItem={updateItem} />
        </div>
        <TypeSelect item={item} updateItem={updateItem} />
        <Delete updateItem={() => updateItem(item, { deleted: true })} />
      </div>
    )
  if (type === TYPES.API) {
    const endpoints = APIS.map(([key, value]) => ({
      value: key,
      label: value.host,
    }))
    const fields = APIS.reduce((obj, [k, v]) => {
      return {
        ...obj,
        [k]: v.fields.map(field => ({ value: field, label: field })),
      }
    }, {})
    return (
      <div className="flex items-baseline pb-8">
        <div className="w-full">
          <TextInput item={item} register={register} />
          <div className="py-4 flex w-full">
            <ApiSelector
              item={item}
              updateItem={updateItem}
              endpoints={endpoints}
              fields={fields}
            />
          </div>
        </div>
        <TypeSelect item={item} updateItem={updateItem} />
        <Delete updateItem={() => updateItem(item, { deleted: true })} />
      </div>
    )
  }
  return (
    <div className="flex items-baseline pb-8">
      <TextInput item={item} register={register} />
      <TypeSelect item={item} updateItem={updateItem} />
      <Delete updateItem={() => updateItem(item, { deleted: true })} />
    </div>
  )
}

const TextInput = ({ item, register }) => {
  return (
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5 w-full">
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor="username"
          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
        >
          Field: {templateFieldType[item.type]}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg flex rounded-md shadow-sm">
            <input
              onKeyPress={e => {
                e.key === 'Enter' && e.preventDefault()
              }}
              key={item._id}
              ref={register({ required: true })}
              defaultValue={item.label}
              type={templateFieldType[item.type]}
              name={item.identifier}
              id={item._id}
              autoComplete={item.label}
              className="mr-4 flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const AddNewInputField = ({
  scroll = () => {},
  update = () => {},
  confirmationModal = () => {},
}) => {
  const [inputRef, setInputFocus] = useFocus()
  const [showNewInputField, setShowNewInputField] = React.useState(false)
  const [newFieldName, setNewFieldName] = React.useState()
  return (
    <div className="pb-8 w-full flex justify-end items-center h-16">
      <div>
        {showNewInputField && (
          <div className="mr-8">
            <div className="w-full">
              <div className="flex">
                <label
                  htmlFor="new"
                  className="mr-2 block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  New
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                      ref={inputRef}
                      onKeyPress={e => {
                        e.key === 'Enter' && e.preventDefault()
                      }}
                      onChange={e => setNewFieldName(e.target.value)}
                      key="new"
                      type={templateFieldType[TYPES.TEXT]}
                      name="new"
                      id="new"
                      className="mr-4 flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {showNewInputField && (
        <div className="pr-4">
          <ActionButton
            modifier="Cancel"
            backgroundColor="bg-gray-300"
            hoverColor="bg-gray-600"
            textColor="text-gray-100"
            actionFn={() => {
              setNewFieldName(null)
              setShowNewInputField(false)
            }}
          />
        </div>
      )}
      <div>
        <ActionButton
          modifier={showNewInputField ? 'Add' : ''}
          backgroundColor={
            !newFieldName?.length && showNewInputField ? 'bg-gray-200' : null
          }
          disabled={showNewInputField && !newFieldName?.length}
          actionFn={() => {
            if (!showNewInputField) {
              setShowNewInputField(true)
              setInputFocus()
            } else if (newFieldName?.length) {
              confirmationModal({
                message:
                  'This will add a new field to the Default template and all the connected devices. Do you want to continue?',
                confimationModalTitle: 'Add a new field',
                confirmButtonLabel: 'Continue',
                onCancel: () => {
                  setInputFocus()
                },
                onConfirm: () => {
                  update(newFieldName, scroll)
                  setNewFieldName(null)
                  setShowNewInputField(false)
                },
              })
            }
          }}
        />
      </div>
    </div>
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const ApiSelector = ({ item, endpoints = [], fields = [], updateItem }) => {
  const [selectedEndpoint, setSelectedEndpoint] = React.useState()
  const [selectedField, setSelectedField] = React.useState()
  const endpoint = endpoints.find(e => e.value === item?.endpoint)
  const defaults = {
    endpoint:
      endpoints.filter(
        e => e.value === selectedEndpoint || e.value === endpoint?.value,
      )?.[0] || endpoints[0],
    fields: fields[selectedEndpoint || endpoints[0].value],
    field:
      fields[selectedEndpoint || item?.endpoint || endpoints[0].value].find(
        f => f.value === selectedField || f.value === item?.field,
      ) || fields[selectedEndpoint || endpoints[0].value][0],
  }
  const update = (value, modifiers) => {
    const { type = 'endpoint' } = modifiers || {}
    const values = {
      endpoint: type === 'endpoint' ? value : defaults.endpoint.value,
      field: type === 'field' ? value : defaults.fields?.[0]?.value,
    }
    updateItem({
      ...item,
      ...values,
    })
  }

  React.useEffect(() => {
    if (!item.endpoint) update(defaults.endpoint.value, { type: 'endpoint' })
    if (!item.field) update(defaults.fields?.[0]?.value, { type: 'field' })
  }, [])
  return (
    <div className="w-full flex">
      <div className="w-1/2 mr-2">
        <DataSelector
          key={1 + selectedEndpoint}
          options={endpoints}
          label="Select API"
          selectValue={setSelectedEndpoint}
          defaultValue={defaults.endpoint}
          update={value => update(value, { type: 'endpoint' })}
        />
      </div>
      <div className="w-1/2">
        <DataSelector
          key={2 + selectedEndpoint + selectedField}
          options={fields[selectedEndpoint || defaults.endpoint.value]}
          label="Select Field"
          defaultValue={defaults.field}
          item={item}
          selectValue={setSelectedField}
          update={value => update(value, { type: 'field' })}
        />
      </div>
    </div>
  )
}

const DataSelector = ({
  options,
  label = 'Select',
  defaultValue,
  update = () => {},
  selectValue = () => {},
}) => {
  const [selected, setSelected] = useState(defaultValue || options?.[0])
  return (
    <Listbox
      value={selected}
      onChange={value => {
        setSelected(value)
        selectValue(value.value)
        update(value.value)
      }}
    >
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium text-gray-700">
            {label}
          </Listbox.Label>
          <div className="mt-1 relative">
            <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <span className="block truncate">{selected?.label}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {options.map(option => (
                  <Listbox.Option
                    key={option.value}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => {
                      return (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate',
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )
                    }}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
