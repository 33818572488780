import * as React from 'react'
import { useModalHooks } from '../../hooks'

export default ({
  seals = [],
  deviceId,
  save = () => {},
  remove = () => {},
  isEdit,
}) => {
  const firstRender = React.useRef()
  const { confirmationModal } = useModalHooks()
  const [items, setItems] = React.useState([])
  const [willBecomeDisabledSeals, setWillBecomeDisabledSeals] = React.useState(
    [],
  )
  const [hasBecomeDisabledSeals, setHasBecomeDisabledSeals] = React.useState([])
  React.useEffect(() => {
    if (seals.length + 1 !== items.length) {
      setItems([...seals, { value: '', valid: false }])
    }
    firstRender.current = true
  }, [seals])
  const updateItem = newItem => {
    return setItems(
      items.map(item => {
        if (!newItem._id && !item._id) return newItem
        if (item._id === newItem._id) {
          if (item.active)
            setWillBecomeDisabledSeals([...willBecomeDisabledSeals, item.value])
          return newItem
        }
        return item
      }),
    )
  }
  return (
    <div>
      <div>
        {firstRender.current &&
          items.map(seal => (
            <SealItem
              key={seal._id}
              {...seal}
              deviceId={deviceId}
              save={save}
              updateItem={updateItem}
              willBecomeDisabledSeals={willBecomeDisabledSeals}
              setHasBecomeDisabledSeals={setHasBecomeDisabledSeals}
              hasBecomeDisabledSeals={hasBecomeDisabledSeals}
            />
          ))}
      </div>

      <div>
        {isEdit && firstRender.current && (
          <button
            onClick={() => {
              confirmationModal({
                message: 'Are you sure you want to remove this device?',
                onConfirm: () => {
                  remove()
                },
              })
            }}
            type="button"
            className={
              'inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-offset-2   bg-red-600 hover:bg-red-700 focus:ring-red-500 sm:w-auto sm:text-sm'
            }
          >
            Delete Device
          </button>
        )}
      </div>
    </div>
  )
}

const SealItem = ({
  _id,
  deviceId,
  value,
  active,
  save,
  updateItem,
  willBecomeDisabledSeals,
  hasBecomeDisabledSeals,
  setHasBecomeDisabledSeals,
}) => {
  const { confirmationModal } = useModalHooks()
  const inputRef = {
    value: React.useRef(),
  }
  const [inputs, setInputs] = React.useState({ value: undefined, active: true })
  React.useEffect(() => {
    if (inputs.value !== value) setInputs({ value, active })
  }, [value])
  const id = {
    value: _id ? _id + 'value' : 'newValue',
  }
  const isAdd = !_id
  const isActive = inputs.active
  const actionButtonLabel = isAdd ? 'Add' : 'Update'
  return (
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
      <div className="flex items-center sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <div className="w-3/12 mt-1 sm:mt-0 sm:col-span-2">
          {_id && (
            <Checkbox
              willBecomeDisabledSeals={willBecomeDisabledSeals}
              hasBecomeDisabledSeals={hasBecomeDisabledSeals}
              updateItem={updateItem}
              item={{ _id, value, active }}
            />
          )}
        </div>
        <div className="w-4/12 mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-full mr-6  flex rounded-md shadow-sm">
            <span
              className={`inline-flex items-center px-3 rounded-l-md ${
                value ? '' : 'border'
              } ${
                !active && value ? 'line-through' : ''
              } border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm`}
            >
              Seal
            </span>
            <input
              type="text"
              name={id.value}
              ref={inputRef.value}
              id={id.value}
              disabled={value}
              value={inputs.value}
              onChange={e => setInputs({ ...inputs, value: e.target.value })}
              className={`${
                value ? 'border-0 bg-gray-50' : ''
              } flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 ${
                !active && value ? 'text-gray-400' : ''
              } rounded-none rounded-r-md sm:text-sm border-gray-300`}
            />
          </div>
        </div>
        <div className="ml-4 flex items-center">
          <button
            onClick={() => {
              const isNew = deviceId && !_id
              if (
                !isNew &&
                !active &&
                willBecomeDisabledSeals.includes(value)
              ) {
                confirmationModal({
                  confimationModalTitle: `Breaking ${value} seal`,
                  message: 'This action is irreversible. Are you sure?',
                  confirmButtonLabel: 'Yes, I am sure',
                  onCancel: () => {
                    updateItem({
                      _id,
                      value,
                      active: true,
                    })
                  },
                  onConfirm: () => {
                    save({
                      ...inputs,
                      active,
                      ...(_id && { _id }),
                      ...(deviceId && { deviceId }),
                    })
                    setHasBecomeDisabledSeals([
                      ...hasBecomeDisabledSeals,
                      value,
                    ])
                  },
                })
              } else
                save({
                  ...inputs,
                  active,
                  ...(_id && { _id }),
                  ...(deviceId && { deviceId }),
                })

              if (isNew) {
                setInputs({
                  value: undefined,
                  active: undefined,
                })
                inputRef.value.current.value = null
              }
            }}
            type="button"
            disabled={
              (!isActive && !isAdd) || hasBecomeDisabledSeals.includes(value)
            }
            className={`${
              isAdd
                ? 'text-gray-100 bg-indigo-700 hover:bg-indigo-900'
                : isActive && !hasBecomeDisabledSeals.includes(value)
                ? 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200'
                : 'text-gray-50 bg-gray-200 cursor-not-allowed'
            } inline-flex items-center px-3.5 py-2.5 border border-transparent text-xs font-medium rounded focus:outline-none`}
          >
            {actionButtonLabel}
          </button>
        </div>
      </div>
    </div>
  )
}

const Checkbox = ({
  item = {},
  willBecomeDisabledSeals,
  hasBecomeDisabledSeals,
  updateItem = () => {},
}) => {
  const checked = item.active
  return (
    <div className={'flex'}>
      <button
        disabled={
          !checked &&
          (!willBecomeDisabledSeals.includes(item.value) ||
            hasBecomeDisabledSeals.includes(item.value))
        }
        onClick={() => {
          return updateItem({
            ...item,
            active: !item.active,
          })
        }}
        type="button"
        aria-pressed={checked}
        className={`${
          checked ? 'bg-indigo-600' : 'bg-gray-200'
        } mr-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      >
        <span className="sr-only">active</span>
        <span
          className={`${
            checked ? 'translate-x-5' : 'translate-x-0'
          } relative inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
        >
          <span
            className={`${
              checked
                ? 'opacity-0 ease-out duration-100'
                : 'opacity-100 ease-in duration-200'
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-gray-400"
              fill="none"
              viewBox="0 0 12 12"
            >
              <path
                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <span
            className={`${
              checked
                ? 'opacity-100 ease-in duration-200'
                : 'opacity-0 ease-out duration-100'
            } absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
            aria-hidden="true"
          >
            <svg
              className="h-3 w-3 text-indigo-600"
              fill="currentColor"
              viewBox="0 0 12 12"
            >
              <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
            </svg>
          </span>
        </span>
      </button>
      <span>active</span>
    </div>
  )
}
