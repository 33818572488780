import * as React from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from '../../utils'
import { ActionButton } from '../'

export default ({ data = [], loading, addDevice = () => {} }) => {
  if (loading) return null
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="pb-8 w-full flex justify-end">
            <ActionButton actionFn={addDevice} />
          </div>
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Last update
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 flex justify-end text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              {data.length ? (
                <tbody>
                  {data.map((item, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                      } hover:bg-green-100`}
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="flex">
                          {item.fields?.find(
                            field => field.templateIdentifierNotMatching,
                          ) ? (
                            <svg
                              className="h-6 w-6 text-red-600"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                              />
                            </svg>
                          ) : (
                            ''
                          )}
                          {item.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDate(item.lastUpdate)}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <div className="flex justify-end items-center">
                          <Link
                            to={`/app/device/${item._id}`}
                            className="text-green-600 hover:text-green-900 pr-4"
                          >
                            View
                          </Link>
                          <Link
                            to={`/app/edit/device/${item._id}`}
                            className={`${
                              item.fields?.find(
                                field => field.templateIdentifierNotMatching,
                              )
                                ? 'px-2 py-1 bg-red-700 text-gray-100 rounded-sm hover:text-white hover:bg-red-900'
                                : 'text-red-400 hover:text-red-700'
                            }`}
                          >
                            Edit
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              ) : null}
            </table>
            {!loading && !data.length && (
              <div className="flex justify-center w-full my-8">No Data</div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
