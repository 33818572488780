import * as React from 'react'
import { saveAs } from 'file-saver'
import { useMutation, useQuery } from '@apollo/client'
import JSZip from 'jszip'
import { ZipView } from '../components'
import {
  GET_DEVICES_NAME_QUERY,
  GENERATE_DEVICE_FILE_TEMPLATES_MUTATION,
} from '../api'
import fileTemplateType from '../settings/enums/_file-template-type.json'
import getEnums from '../settings/enums'

// TODO:
// - tabs are lost once pressed in configuration file page

export default ({ deviceName }) => {
  const [isValidName, setIsValidName] = React.useState()
  const zipped = React.useRef()
  const [getFilesContent, { loading: isRetrievingFiles }] = useMutation(
    GENERATE_DEVICE_FILE_TEMPLATES_MUTATION,
  )
  const { data: { getDevicesName: names = [] } = {}, loading } = useQuery(
    GET_DEVICES_NAME_QUERY,
    {
      skip: !deviceName,
    },
  )

  const generateZip = () => {
    if (!isRetrievingFiles && !zipped.current) {
      zipped.current = true
      getFilesContent({
        variables: {
          deviceName,
        },
      }).then(({ data: { generateDeviceFileTemplates: data = [] } } = {}) => {
        var zip = new JSZip()
        const folder = zip.folder('files')
        const fileNames = getEnums(fileTemplateType, 'values')
        data.forEach(function(file, idx) {
          folder.file(`config-${fileNames[idx]}.ini`, file)
        })
        folder.generateAsync({ type: 'blob' }).then(
          function(blob) {
            // 1) generate the zip file
            saveAs(blob, `device_${deviceName}.zip`) // 2) trigger the download
          },
          function(err) {
            console.log(err)
          },
        )
      })
    }
  }

  React.useEffect(() => {
    if (!isValidName && names.length) {
      if (
        names
          .map(name => name.toLowerCase())
          .includes(deviceName?.toLowerCase())
      ) {
        setIsValidName(true)
      }
    }
  }, [names])
  if (!deviceName) return <div>No device name was supplied</div>
  if (loading) return <div>...checking device name validity</div>
  if (!isValidName) return <div>Supplied device's name is not valid</div>
  if (isValidName && !isRetrievingFiles) generateZip()
  return <ZipView />
}
