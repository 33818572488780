import * as React from 'react'

export default ({ tags: propTags, selectedTags = () => {} }) => {
  const [tags, setTags] = React.useState(propTags)
  const removeTags = indexToRemove => {
    const tagsLeft = [...tags.filter((_, index) => index !== indexToRemove)]
    setTags(tagsLeft)
    selectedTags(tagsLeft)
  }
  const addTags = event => {
    if (event.target.value !== '') {
      setTags([...tags, event.target.value])
      selectedTags([...tags, event.target.value])
      event.target.value = ''
    }
  }
  return (
    <div className="w-4/5">
      <ul id="tags" className="flex flex-wrap">
        {tags.map((tag, index) => (
          <li key={index} className="mb-2 mr-2">
            <div className="flex items-center">
              <span className="py-1 pl-2 bg-gray-600 text-gray-100 inline-block pr-4">
                {tag}
              </span>
              <span
                className="cursor-pointer p-2 py-1 bg-gray-200 text-red-400 inline-block"
                onClick={() => removeTags(index)}
              >
                x
              </span>
            </div>
          </li>
        ))}
      </ul>
      <input
        className="mr-4 flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
        type="text"
        onKeyPress={e => {
          e.key === 'Enter' && e.preventDefault()
        }}
        onKeyUp={event => {
          return event.key === 'Enter' ? addTags(event) : null
        }}
        placeholder="Type and press enter to add values"
      />
    </div>
  )
}
