import * as React from 'react'
import { useModal } from 'react-imperial-modal'

const ConfirmPrompt = ({
  close,
  message = '',
  confimationModalTitle = 'Please confirm',
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  hideCancelButton: isPromptOnly,
}) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            {!isPromptOnly && (
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
            )}
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {confimationModalTitle}
              </h3>
              <div className="mt-2">{message}</div>
            </div>
          </div>
          <div
            className={`mt-5 sm:mt-4 sm:flex ${
              isPromptOnly ? 'justify-center py-4' : 'sm:ml-10 sm:pl-4'
            }`}
          >
            <button
              onClick={() => close(true)}
              type="button"
              className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                isPromptOnly
                  ? ' bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                  : ' bg-red-600 hover:bg-red-700 focus:ring-red-500'
              } sm:w-auto sm:text-sm`}
            >
              {confirmButtonLabel}
            </button>
            {!isPromptOnly && (
              <button
                onClick={() => close(false)}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {cancelButtonLabel}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const ConcatenatePrompt = ({
  close,
  message = '',
  confimationModalTitle = 'Please confirm',
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  hideCancelButton: isPromptOnly,
  additionalFields = () => {},
}) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            {!isPromptOnly && (
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
            )}
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {confimationModalTitle}
              </h3>
              <div className="mt-2">{message}</div>
              <div className="mt-2">{additionalFields()}</div>
            </div>
          </div>
          <div
            className={`mt-5 sm:mt-4 sm:flex ${
              isPromptOnly ? 'justify-center py-4' : 'sm:ml-10 sm:pl-4'
            }`}
          >
            <button
              onClick={() => close(true)}
              type="button"
              className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                isPromptOnly
                  ? ' bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                  : ' bg-red-600 hover:bg-red-700 focus:ring-red-500'
              } sm:w-auto sm:text-sm`}
            >
              {confirmButtonLabel}
            </button>
            {!isPromptOnly && (
              <button
                onClick={() => close(false)}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {cancelButtonLabel}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const SliceReplacePrompt = ({
  close,
  message = '',
  confimationModalTitle = 'Please confirm',
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  hideCancelButton: isPromptOnly,
  customImport,
}) => {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>

        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="sm:flex sm:items-start">
            {!isPromptOnly && (
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <svg
                  className="h-6 w-6 text-red-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                  />
                </svg>
              </div>
            )}
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-lg leading-6 font-medium text-gray-900"
                id="modal-headline"
              >
                {confimationModalTitle}
              </h3>
              <div className="mt-2">{message}</div>
              <div className="mt-2">{customImport()}</div>
            </div>
          </div>
          <div
            className={`mt-5 sm:mt-4 sm:flex ${
              isPromptOnly ? 'justify-center py-4' : 'sm:ml-10 sm:pl-4'
            }`}
          >
            <button
              onClick={() => close(true)}
              type="button"
              className={`inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                isPromptOnly
                  ? ' bg-blue-600 hover:bg-blue-700 focus:ring-blue-500'
                  : ' bg-red-600 hover:bg-red-700 focus:ring-red-500'
              } sm:w-auto sm:text-sm`}
            >
              {confirmButtonLabel}
            </button>
            {!isPromptOnly && (
              <button
                onClick={() => close(false)}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 bg-white text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                {cancelButtonLabel}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default () => {
  const [openModal, closeModal] = useModal()

  const confirmationModal = async ({
    message = '',
    confimationModalTitle,
    confirmButtonLabel,
    cancelButtonLabel,
    hideCancelButton,
    onConfirm = () => {},
    onCancel = () => {},
  } = {}) => {
    const promptModal = (
      <ConfirmPrompt
        message={message}
        confimationModalTitle={confimationModalTitle}
        confirmButtonLabel={confirmButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        hideCancelButton={hideCancelButton}
        close={res => closeModal(promptModal, res)}
      />
    )
    const ok = await openModal(promptModal)

    if (ok) {
      onConfirm()
    } else onCancel()
  }

  const concatenateModal = async ({
    message = '',
    confimationModalTitle,
    confirmButtonLabel,
    cancelButtonLabel,
    hideCancelButton,
    onConfirm = () => {},
    onCancel = () => {},
    additionalFields = () => {},
  } = {}) => {
    const promptModal = (
      <ConcatenatePrompt
        message={message}
        additionalFields={additionalFields}
        confimationModalTitle={confimationModalTitle}
        confirmButtonLabel={confirmButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        hideCancelButton={hideCancelButton}
        close={res => closeModal(promptModal, res)}
      />
    )
    const ok = await openModal(promptModal)

    if (ok) {
      onConfirm()
    } else onCancel()
  }

  const sliceReplaceModal = async ({
    message = '',
    confimationModalTitle,
    confirmButtonLabel,
    cancelButtonLabel,
    hideCancelButton,
    customImport,
    onConfirm = () => {},
    onCancel = () => {},
  } = {}) => {
    const promptModal = (
      <SliceReplacePrompt
        message={message}
        confimationModalTitle={confimationModalTitle}
        confirmButtonLabel={confirmButtonLabel}
        cancelButtonLabel={cancelButtonLabel}
        hideCancelButton={hideCancelButton}
        customImport={customImport}
        close={res => closeModal(promptModal, res)}
      />
    )
    const ok = await openModal(promptModal)

    if (ok) {
      onConfirm()
    } else onCancel()
  }
  return {
    confirmationModal,
    sliceReplaceModal,
    concatenateModal,
  }
}
