import * as React from 'react'
import { formatDate } from '../../utils'

export default ({ data = { fields: [], revisions: [], seals: [] } }) => {
  const [items, setItems] = React.useState([])
  const [revisions, setRevisions] = React.useState([])
  const [seals, setSeals] = React.useState([])
  React.useState(() => {
    if (data.fields.length !== items.length) {
      const newItems = data.fields.reduce((arr, item) => {
        return [
          ...arr,
          { id: item._id, label: item.templateField?.label, value: item.value },
        ]
      }, [])

      setItems(newItems)
    }
    if (data.revisions.length !== revisions.length) {
      const newRevisions = data.revisions
        .map(i => i)
        .sort((a, b) => {
          const bDate = new Date(b.date)
          const aDate = new Date(a.date)
          if (bDate && aDate) return new Date(b.date) - new Date(a.date)
          return 0
        })
        .reduce((arr, item) => {
          const formattedDate = formatDate(item.date, { compact: true })
          return [
            ...arr,
            {
              id: item._id,
              content: item.change,
              date: formattedDate !== '1/1/1970' ? formattedDate : 'n/d',
              link: item.ticketNumber
                ? `http://waat.assembla.com/spaces/resistell/tickets/${item.ticketNumber}`
                : null,
            },
          ]
        }, [])

      setRevisions(newRevisions)
    }
    if (data.seals.length !== seals.length) {
      const newSeals = data.seals.reduce((arr, item) => {
        return [
          ...arr,
          {
            id: item._id,
            value: item.value,
            isActive: item.active,
          },
        ]
      }, [])

      setSeals(newSeals)
    }
  }, [data])
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="w-full flex justify-between">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {data.name}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Device's details
          </p>
        </div>
        <div>
          <button
            type="button"
            className="bg-green-400 py-2 px-4 rounded-md shadow-sm text-sm font-medium text-gray-200 hover:bg-green-200 hover:text-gray-700"
            onClick={() => {
              const win = window.open(`/zip/${data.name}`, '_blank')
              win.focus()
            }}
          >
            Zip File
          </button>
        </div>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          {items.map(item => (
            <div
              key={item.id}
              className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            >
              <dt className="text-sm font-medium text-gray-500">
                {item.label}
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {item.value}
              </dd>
            </div>
          ))}
          {!!revisions.length && <Revisions revisions={revisions} />}
          {!!seals.length && <Seals seals={seals} />}
        </dl>
      </div>
    </div>
  )
}

const Revisions = ({ revisions = [] }) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">Revisions</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
          {revisions.map(revision => (
            <li
              key={revision.id}
              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div className="w-0 flex-1 flex items-center">
                <svg
                  className="flex-shrink-0 h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                </svg>
                <div className="px-2 py-1 bg-gray-100 w-24 flex justify-center ml-2">
                  {revision.date}
                </div>
                <span className="flex-grow pl-4">{revision.content}</span>
              </div>
              {revision.link && (
                <div className="ml-4 flex-shrink-0">
                  <a
                    href={revision.link}
                    target="_blank"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Link
                  </a>
                </div>
              )}
            </li>
          ))}
        </ul>
      </dd>
    </div>
  )
}
const Seals = ({ seals = [] }) => {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt className="text-sm font-medium text-gray-500">Seals</dt>
      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
          {seals.map(seal => (
            <li
              key={seal.id}
              className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
            >
              <div className="w-0 flex-1 flex items-center">
                {seal.isActive ? (
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                ) : (
                  <svg
                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
                <span
                  className={`ml-2 flex-1 w-0 truncate ${
                    !seal.isActive ? 'line-through' : ''
                  }`}
                >
                  {seal.value}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </dd>
    </div>
  )
}
