import * as React from 'react'
import { PageWrapper } from '../components'
import { ConfigurationFilesConatiner } from '../containers'

export default () => {
  return (
    <PageWrapper title="Configuration files">
      <ConfigurationFilesConatiner />
    </PageWrapper>
  )
}
