import * as React from 'react'
import { PageWrapper } from '../components'
import { ZipContainer } from '../containers'

export default ({ match: { params } = {} } = {}) => {
  return (
    <PageWrapper title="Zipped Configuration Files">
      <ZipContainer deviceName={params?.device} />
    </PageWrapper>
  )
}
