import React from 'react'
import { Switch, Route, Link } from 'react-router-dom'
import {
  DeviceListPage,
  TemplatePage,
  DevicePageEdit,
  DevicePageView,
  ConfigurationFilesPage,
  ConfigurationFilePage,
} from '../pages'
import useAuth from '../components/general/_use-auth'

// const isActive = (Component, active) => (
//   <Component className="mr-2" style={{ ...(active && { color: 'red' }) }} />
// )

const routes = [
  {
    path: '/app/device-list',
    exact: true,
    label: 'Devices',
    // icon: ({ active } = {}) => isActive(RoomIcon, active),
    // sidebar: () => <div />,
    main: () => <DeviceListPage />,
  },
  {
    path: '/app/template',
    exact: true,
    label: 'Template',
    // icon: ({ active } = {}) => isActive(RoomIcon, active),
    // sidebar: () => <div />,
    main: () => <TemplatePage />,
  },
  {
    path: '/app/configuration-files',
    exact: true,
    label: 'Configuration files',
    main: () => <ConfigurationFilesPage />,
  },
]

export default ({ match, location }) => {
  const { logout } = useAuth()
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false)
  const { pathname } = location || {}
  const path = pathname === '/app' ? routes[0].path : pathname
  return (
    <div className="min-h-screen bg-white">
      <nav className="bg-white border-b border-gray-200">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <img
                  className="block lg:hidden h-8 w-auto mr-4"
                  src="https://d33wubrfki0l68.cloudfront.net/f9949b49beecc8e1f1e66303dffa5687307f3185/f71aa/images/resistell-logo.png"
                  alt="Resistell"
                />
                <img
                  className="hidden lg:block h-8 w-auto"
                  src="https://d33wubrfki0l68.cloudfront.net/f9949b49beecc8e1f1e66303dffa5687307f3185/f71aa/images/resistell-logo.png"
                  alt="Resistell"
                />
              </div>
              {routes.map(route => (
                <div
                  key={route.path}
                  className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8"
                >
                  <Link
                    to={route.path}
                    className={
                      path === route.path
                        ? 'border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                    }
                  >
                    {/* {route.icon({ active: path === route.path })} */}
                    {route.label}
                  </Link>
                </div>
              ))}
              <div className="hidden sm:ml-6 sm:flex sm:items-center">
                <div className="ml-3 relative">
                  <div>
                    <button
                      onClick={() => setIsUserMenuOpen(!isUserMenuOpen)}
                      className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      id="user-menu"
                      aria-haspopup="true"
                    >
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src="https://eu.ui-avatars.com/api/?name=Admin"
                        alt=""
                      />
                    </button>
                  </div>
                  {/* <!--
                  Profile dropdown panel, show/hide based on dropdown state.

                  Entering: "transition ease-out duration-200"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                --> */}
                  {isUserMenuOpen && (
                    <div
                      className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      <span
                        onClick={() => logout()}
                        className="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Sign out
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="-mr-2 flex items-center sm:hidden">
                <button
                  onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                  className="bg-white inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">Open main menu</span>

                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                  <svg
                    className="hidden h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          {isMobileMenuOpen && (
            <>
              <div className="sm:hidden">
                {routes.map(route => (
                  <div className="pt-2 pb-3 space-y-1">
                    <Link
                      to={route.path}
                      className={
                        path === route.path
                          ? 'bg-indigo-50 border-indigo-500 text-indigo-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                      }
                    >
                      {/* {route.icon({ active: path === route.path })} */}
                      {route.label}
                    </Link>
                  </div>
                ))}
              </div>
              <div className="pt-4 pb-3 border-t border-gray-200">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://eu.ui-avatars.com/api/?name=Admin"
                      alt=""
                    />
                  </div>
                </div>
                <div className="mt-3 space-y-1">
                  <span
                    onClick={() => logout()}
                    className="cursor-pointer block px-4 py-2 text-base font-medium text-gray-500 hover:text-gray-800 hover:bg-gray-100"
                  >
                    Sign out
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </nav>
      <Switch>
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            children={<route.main />}
          />
        ))}
        <Route exact path="/app/configuration-files/:id?" component={(props) => <ConfigurationFilePage {...props} />} />
        <Route exact path="/app/device/:id?" component={DevicePageView} />
        <Route exact path="/app/edit/device/:id?" component={DevicePageEdit} />
        {/* default route if no path matches */}
        <Route component={DeviceListPage} />
      </Switch>
    </div>
  )
}
