import { gql } from '@apollo/client'
import { Template, Device, Revision, Seal, FileTemplates } from './_fragments'

// REMOTE
export const LOGIN_MUTATION = gql`
  mutation login($userCredentials: userCredentials!) {
    login(input: $userCredentials)
  }
`

export const UPDATE_DEFAULT_TEMPLATE_MUTATION = gql`
  mutation updateDefaultTemplate($data: InputDefaultTemplate!) {
    updateDefaultTemplate(input: $data) {
      ...TemplateData
    }
  }
  ${Template.fragments.TemplateData}
`

export const UPDATE_DEVICE_MUTATION = gql`
  mutation updateDevice($data: InputDevice!) {
    updateDevice(input: $data) {
      ...DeviceData
    }
  }
  ${Device.fragments.DeviceData}
`

export const CREATE_DEVICE_MUTATION = gql`
  mutation createDevice($data: InputDevice!) {
    createDevice(input: $data) {
      ...DeviceData
    }
  }
  ${Device.fragments.DeviceData}
`

export const ADD_FIELD_TO_DEFAULT_TEMPLATE_MUTATION = gql`
  mutation addDefaultTemplateField($name: String!) {
    addDefaultTemplateField(name: $name) {
      ...TemplateData
    }
  }
  ${Template.fragments.TemplateData}
`

export const DELETE_DEVICE_MUTATION = gql`
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) {
      ...DeviceData
    }
  }
  ${Device.fragments.DeviceData}
`

export const SAVE_REVISION_MUTATION = gql`
  mutation saveRevision($input: InputRevision!) {
    saveRevision(input: $input) {
      ...RevisionData
    }
  }
  ${Revision.fragments.RevisionData}
`

export const SAVE_SEAL_MUTATION = gql`
  mutation saveSeal($input: InputSeal!) {
    saveSeal(input: $input) {
      ...SealData
    }
  }
  ${Seal.fragments.SealData}
`

export const UPDATE_FILE_TEMPLATE_MUTATION = gql`
  mutation updateFileTemplate($data: InputFileTemplate!) {
    updateFileTemplate(input: $data) {
      ...FileTemplatesData
    }
  }
  ${FileTemplates.fragments.FileTemplatesData}
`
export const GENERATE_DEVICE_FILE_TEMPLATES_MUTATION = gql`
  mutation generateDeviceFileTemplates($deviceName: String!) {
    generateDeviceFileTemplates(deviceName: $deviceName)
  }
`
