import * as React from 'react'
import { PageWrapper } from '../components'
import { DeviceContainer } from '../containers'

export default ({ match: { params } = {} } = {}) => {
  const hasId = params.id
  return (
    <PageWrapper title="Device">
      <div>
        {hasId ? (
          <>
            <DeviceContainer id={params.id} isViewOnly />
          </>
        ) : null}
      </div>
    </PageWrapper>
  )
}
