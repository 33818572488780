import React, { useState, useEffect, useRef } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { Switch } from '@headlessui/react'
import { useModalHooks } from '../hooks'
import { useToasts } from 'react-toast-notifications'
import fileTemplateType from '../settings/enums/_file-template-type.json'

import Prism from 'prismjs'
import 'prismjs/components/prism-ini'

import './_editor.css'

import {
  GET_ALL_TEMPLATE_FIELDS_QUERY,
  GET_FILE_TEMPLATE_BY_QUERY,
  UPDATE_FILE_TEMPLATE_MUTATION,
} from '../api'

// Array diff utility
const diff = (current = [], next = []) => {
  const additional = next.filter(item => !current.includes(item))
  const missing = current.filter(item => !next.includes(item))
  const common = current.filter(item => next.includes(item))
  return {
    additional: [...additional],
    missing: [...missing],
    common: [...new Set(common)],
  }
}

const createMultiObject = (object = {}, additional = {}) => {
  const { _id: id, label, identifier } = additional
  let additionals = []
  if (object.additionals?.length) {
    additionals = [...object.additionals, { id, label, identifier }]
  } else additionals = [{ id, label, identifier }]
  return {
    ...object,
    label: `${object.label} / ${label}`,
    additionals,
  }
}

/**
 * Context menu with items from server.
 *
 * @param {Object} object Callback function, is visible and position.
 * @return {Object}
 */
const ContextMenu = ({ visible, posX, posY, data, applyOnChoose }) => {
  const { sliceReplaceModal, concatenateModal } = useModalHooks()
  const multipleFields = React.useRef([])

  var replace = {}

  var slice = null
  var list = null
  var handleChange = e => {
    if (e.target.name === 'slice') {
      slice = e.target.value
    } else {
      replace[e.target.name] = e.target.value
    }
  }

  const replaceModal = (object, { multiIndex = -1, label = '' } = {}) => {
    sliceReplaceModal({
      customImport: () => {
        return (
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Replace
              </label>
              <input
                onChange={handleChange}
                name="replace_0"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
              />
              <span>----</span>
              <input
                onChange={handleChange}
                name="replace_1"
                className="shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Slice
              </label>
              <input
                onChange={handleChange}
                name="slice"
                className="shadow appearance-none border rounded w-full py-2 px-3 mb-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
              />
            </div>
          </div>
        )
      },
      confimationModalTitle: label ? `Modifiers for [${label}]` : 'Modifiers',
      cancelButtonLabel: 'Skip',
      confirmButtonLabel: 'Apply',
      message: 'You can modify some values of string',
      onConfirm: () => {
        var replaceSlices = []

        if (typeof replace.replace_0 !== 'undefined') {
          replaceSlices.push(replace.replace_0)
        }

        if (typeof replace.replace_1 !== 'undefined') {
          replaceSlices.push(replace.replace_1)
        }

        var newObject = {
          ...object,
        }
        if (multiIndex >= 0) {
          // handling additional fields
          newObject.additionals[multiIndex] = {
            ...newObject.additionals[multiIndex],
            replace: [...replaceSlices],
            slice: slice,
          }
        } else {
          newObject.replace = [...replaceSlices]
          newObject.slice = slice
        }

        applyOnChoose(newObject)
      },
      onCancel: () => {
        var newObject = {
          ...object,
          replace: [],
          slice: null,
        }

        applyOnChoose(newObject)
      },
    })
  }

  const onSelectMultipleFields = field => {
    const currentFields = multipleFields.current
    if (currentFields.find(({ _id }) => field._id === _id)) {
      multipleFields.current = multipleFields.current.filter(
        ({ _id }) => _id !== field._id,
      )
    } else multipleFields.current = [...multipleFields.current, field]
  }

  var onChoose = function(e, fields = []) {
    var object = {
      label: `${e.currentTarget.getAttribute('label')}`,
      id: e.currentTarget.getAttribute('id'),
      identifier: e.currentTarget.getAttribute('identifier'),
    }
    if (typeof applyOnChoose === 'function') {
      return concatenateModal({
        confimationModalTitle: 'Fields Selection',
        cancelButtonLabel: 'Use this field Only',
        confirmButtonLabel: 'Add one more fields',
        message: (
          <>
            You can use just <strong>"{object.label}"</strong> or add more
            fields
          </>
        ),
        onConfirm: () => {
          concatenateModal({
            additionalFields: () => {
              return (
                <div className="my-4 flex flex-wrap h-72 overflow-auto">
                  {fields
                    .filter(f => f._id !== object.id)
                    .map(field => (
                      <div className="flex w-full">
                        <div className="p-2">
                          <Checkbox
                            field={field}
                            update={onSelectMultipleFields}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              )
            },
            confimationModalTitle: 'Additional Fields Selection',
            cancelButtonLabel: 'Cancel',
            confirmButtonLabel: 'Apply',
            message: 'Include more field(s)',
            onConfirm: () => {
              let newObj = [...multipleFields.current].reduce(
                (obj, current) => {
                  return {
                    ...obj,
                    ...createMultiObject(obj, current),
                  }
                },
                object,
              )
              ;[newObj, ...multipleFields.current].map((current, index) => {
                const paramIndex = index - 1
                const label = paramIndex >= 0 ? current.label : object.label
                replaceModal(newObj, {
                  multiIndex: paramIndex,
                  label,
                })
              })
              multipleFields.current = []
            },
            onCancel: () => {
              replaceModal(object)
              multipleFields.current = []
            },
          })
        },
        onCancel: () => {
          replaceModal(object)
          multipleFields.current = []
        },
      })
    }
  }

  if (data?.fields?.length) {
    list = data.fields.map(item => {
      return (
        <span
          onClick={e => onChoose(e, data.fields)}
          key={item._id}
          id={item._id}
          identifier={item.identifier}
          label={item.label}
          className="contextMenu-item"
        >
          {item.label}
        </span>
      )
    })
  }

  const contextMenuFixedHeight = 495

  return (
    <ul
      style={{
        top:
          contextMenuFixedHeight + posY > window.innerHeight
            ? window.innerHeight - contextMenuFixedHeight - 50
            : posY,
        left: posX,
      }}
      className={`contextMenu ${visible ? 'visible' : 'hidden'}`}
    >
      {list}
    </ul>
  )
}

/**
 * Check is cursor on the end of the word or on the begining.
 * If it's on the middle, disable enter functionality.
 *
 * @param {Object} event Keyboard object.
 * @param {string} value Value for validation.
 * @return {boolean}
 */
var shouldAllowEnter = function(event, value) {
  var shouldDisable = false

  var prevValue =
    typeof event.currentTarget.value.split(value)[0] !== 'undefined'
      ? event.currentTarget.value.split(value)[0]
      : ''

  var lineInfoBasedOnCursor = value.substr(
    0,
    event.currentTarget.selectionStart - prevValue.length,
  )

  // if curor not on the end of value
  if (lineInfoBasedOnCursor.length !== value.length) {
    shouldDisable = true
  }

  // if curor at the begining of the value
  if (lineInfoBasedOnCursor === '') {
    shouldDisable = false
  }

  if (shouldDisable && event.keyCode === 13) {
    event.preventDefault()
  }

  return shouldDisable
}

const CodeEditor = props => {
  const { addToast } = useToasts()
  const configType = props.match?.params?.id || props.configType

  const { data: { getAllDefaultTemplateFields } = {}, loading } = useQuery(
    GET_ALL_TEMPLATE_FIELDS_QUERY,
  )

  const { data: { getFileTemplateByType } = {} } = useQuery(
    GET_FILE_TEMPLATE_BY_QUERY,
    {
      variables: {
        type: configType,
      },
    },
  )

  const [update] = useMutation(UPDATE_FILE_TEMPLATE_MUTATION)

  const [lines, setLines] = useState([])

  const [data, setData] = React.useState({})
  const [content, setContent] = useState('')
  const [posY, setPosY] = useState(0)
  const [posX, setPosX] = useState(0)
  const [visible, setVisible] = useState(false)

  const lineOnChangeRef = useRef(0)
  const lineOnPressRef = useRef(0)
  const lineOnSelectRef = useRef(0)

  const currentValueOnPressRef = useRef('')
  const currentValueOnChangeRef = useRef('')

  const keyCodeRef = useRef()

  var allowContextMenu = false

  const onSelection = function(e) {
    e.persist()
    lineOnSelectRef.current =
      e.target.value.substr(0, e.currentTarget.selectionStart).split('\n')
        .length - 1

    if (e.currentTarget.selectionStart < e.currentTarget.selectionEnd) {
      // allow to open context menu
      allowContextMenu = true
    } else {
      allowContextMenu = false
      setVisible(false)
    }
  }

  const onKey = function(e) {
    var value = e.target.value

    keyCodeRef.current = e.keyCode
    lineOnPressRef.current =
      value.substr(0, e.currentTarget.selectionStart).split('\n').length - 1
    currentValueOnPressRef.current = value.split('\n')[lineOnPressRef.current]

    shouldAllowEnter(e, currentValueOnPressRef.current)
  }

  const onChange = function(e) {
    var value = e.target.value
    const previousValue = value.split('\n')
    var newContent = [...lines]

    lineOnChangeRef.current =
      value.substr(0, e.currentTarget.selectionStart).split('\n').length - 1
    var newLine = value.split('\n')[lineOnChangeRef.current]
    currentValueOnChangeRef.current = newLine

    // if change happends on same line or not
    if (lineOnPressRef.current === lineOnChangeRef.current) {
      console.log('on the same line')

      // on the same line and editing context menu value
      if (newLine.includes('*')) {
        console.log('manualy editing context menu')

        var newValue = newLine.split('*')[0]
        value = value.split('\n')

        value[lineOnChangeRef.current] = newValue
        value = value.join('\n')

        newContent.splice(lineOnChangeRef.current, 1, newValue)
      } else {
        // editing value from editor

        newContent.splice(lineOnChangeRef.current, 1, newLine)
      }
    } else {
      console.log('new line')
      // on the new line
      if (keyCodeRef.current === 8) {
        // if not have value and removing space
        if (currentValueOnPressRef.current === '') {
          newContent.splice(lineOnSelectRef.current, 1)
        }

        // if current line have value
        if (currentValueOnPressRef.current !== '') {
          // if we moving value from context menu
          if (currentValueOnPressRef.current.includes(' *')) {
            newContent.splice(lineOnChangeRef.current, 1)
            newContent.splice(
              lineOnChangeRef.current,
              1,
              lines[lineOnPressRef.current],
            )
          } else {
            // if we moving value from editor
            newContent.splice(lineOnChangeRef.current, 1)
            newContent.splice(
              lineOnChangeRef.current,
              1,
              currentValueOnPressRef.current,
            )
          }
        }
      }

      if (keyCodeRef.current === 13) {
        if (newLine === '') {
          // ako je prazan string, dodaj ako je enter
          newContent.splice(lineOnChangeRef.current, 0, newLine)
        } else {
          // ako postoji vrednost na liniji zameni mesta
          newContent.splice(lineOnSelectRef.current, 0, '')
          newContent.splice(
            lineOnChangeRef.current,
            1,
            newContent[lineOnChangeRef.current],
          )
        }
      }
    }

    // compare the arrays before setting new lines/content
    const comparison = diff(
      previousValue,
      newContent.map(
        item => (typeof value === 'string' ? item : item.value), // object to actual value
      ),
    )
    // console.log({ previousValue, comparison })
    const validValues = [...comparison.common, ...comparison.missing] // merge filtered with new
    const finalContent = previousValue.filter(pv => validValues.includes(pv))
    const finalLines = finalContent
      .map(fc => {
        const existingObj = newContent.find(nc => nc.value === fc)
        if (existingObj) return existingObj
        return fc
      })
      .filter(nc => validValues.includes(nc) || validValues.includes(nc.value))
    if (value === '') {
      setLines([])
    } else {
      setLines([...finalLines])
    }

    setContent(finalContent.join('\n'))
    // console.log({ validValues, finalLines, finalContent, newContent })
  }

  var applyOnChoose = function(item) {
    var newLines = [...lines]
    var newContent = content.split('\n')

    var selectedLine = content.split('\n')[lineOnSelectRef.current]

    if (selectedLine.includes('=')) {
      selectedLine = selectedLine.split('=')[0]
    }

    var newItem = {
      ...item,
      value: `${selectedLine}=${item.label} *`,
    }

    newLines[lineOnSelectRef.current] = newItem

    newContent.splice(
      lineOnSelectRef.current,
      1,
      `${selectedLine}=${item.label} *`,
    )

    allowContextMenu = false
    setVisible(false)
    setLines([...newLines])
    setContent(newContent.join('\n'))
  }

  const onMenu = function(e) {
    e.preventDefault()

    if (!allowContextMenu) {
      return
    }

    setPosX(e.pageX)
    setPosY(e.pageY)
    setVisible(true)
  }

  var onSubmit = function() {
    update({
      variables: {
        data: {
          _id: getFileTemplateByType._id,
          configurationType: getFileTemplateByType.configurationType,
          content: lines,
        },
      },
    })
      .then(() => {
        addToast('You successfully changed file!', {
          appearance: 'success',
          autoDismiss: true,
        })
      })
      .catch(err => {
        addToast('Something went wrong!', {
          appearance: 'warning',
          autoDismiss: true,
        })
      })
  }

  useEffect(() => {
    if (typeof getFileTemplateByType !== 'undefined') {
      var content = []

      getFileTemplateByType.content.map(item => {
        if (typeof item === 'object') {
          content.push(item.value)
        }

        if (typeof item === 'string') {
          content.push(item)
        }
      })
      setLines([...getFileTemplateByType.content])
      setContent(content.join('\n'))
    }
  }, [getFileTemplateByType])

  useEffect(() => {
    Prism.highlightAll()
    const editorLines = [
      ...document.getElementById('prism-editor').childNodes,
    ].filter(e => e.textContent !== '\n' && !!e.innerText)
    // console.log(editorLines.map(i => i.innerText))
    lines
      .reduce((arr, l) => {
        if (l.value)
          return [
            ...arr,
            ...l.value.split('=').map(v => ({
              value: v,
            })),
          ]

        if (l.includes('=')) return [...arr, ...l.split('=')]
        return [...arr, l]
      }, [])
      .filter(l => l !== '')
      .forEach(function(line, index) {
        // console.log(
        //   line.value ? '[obj]' : 'str',
        //   line.value || line,
        //   editorLines[index] && editorLines[index].textContent,
        // )
        if (line.value && editorLines[index] && editorLines[index].classList)
          editorLines[index].classList.add('active')
      })
  }, [props.language, content])

  /**
   * Set scroll position on editor.
   *
   * @param {int} scrollPos Scroll position
   * @return {void}
   */
  var copyScrollPosition = function(scrollPos) {
    document.getElementsByClassName('code-output')[0].scrollTop = scrollPos
  }

  /**
   * Attach scroll handler on textarea.
   * Get scroll position and copy to editor.
   *
   * @return {void}
   */
  var attachScrollHandler = function() {
    let lastKnownScrollPosition = 0
    let ticking = false
    var textarea = document.getElementsByClassName('code-input')[0]

    textarea.addEventListener('scroll', function(e) {
      lastKnownScrollPosition = e.target.scrollTop

      if (!ticking) {
        window.requestAnimationFrame(function() {
          copyScrollPosition(lastKnownScrollPosition)
          // after we set editor scroll top, set same value to textarea
          textarea.scrollTop = document.getElementsByClassName(
            'code-output',
          )[0].scrollTop

          ticking = false
        })

        ticking = true
      }
    })
  }

  useEffect(() => {
    attachScrollHandler()
  }, [])

  useEffect(() => {
    if (
      !loading &&
      getAllDefaultTemplateFields?.length !== data?.fields?.length
    ) {
      const transformedData = {
        fields: getAllDefaultTemplateFields
          .slice()
          .sort((a, b) => a.order - b.order),
      }
      setData(transformedData)
    }
  }, [getAllDefaultTemplateFields])
  console.log(posY, window.innerHeight)
  return (
    <>
      <h2>File config-{fileTemplateType[configType]}</h2>
      <div className="code-edit-container">
        <ContextMenu
          applyOnChoose={applyOnChoose}
          data={data}
          posX={posX}
          posY={posY}
          visible={visible}
        />
        <textarea
          id="editor"
          onContextMenu={onMenu}
          className="code-input"
          value={content}
          onSelect={onSelection}
          onChange={onChange}
          onKeyDown={onKey}
        />
        <pre className="code-output line-numbers">
          <code id="prism-editor" className="language-ini" datalinenumber={1}>
            {content}
          </code>
        </pre>
      </div>
      <button
        onClick={onSubmit}
        type="submit"
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save
      </button>
    </>
  )
}

export default CodeEditor

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Checkbox = ({ field, update = () => {} }) => {
  const [enabled, setEnabled] = useState(false)

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={value => {
          setEnabled(value)
          update(field)
        }}
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        )}
      >
        <span className="sr-only">Use setting</span>
        <span
          aria-hidden="true"
          className={classNames(
            enabled ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">
          {field.label}{' '}
        </span>
      </Switch.Label>
    </Switch.Group>
  )
}
