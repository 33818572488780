import * as React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useToasts } from 'react-toast-notifications'
import { GET_NEW_EMPTY_DEVICE_QUERY, CREATE_DEVICE_MUTATION } from '../api'
import { DeviceViewEdit } from '../components'
import history from '../history'

export default ({ id } = {}) => {
  const [data, setData] = React.useState()
  const firstRender = React.useRef()
  const { addToast } = useToasts()
  const { data: { getNewEmptyDevice } = {}, loading } = useQuery(
    GET_NEW_EMPTY_DEVICE_QUERY,
  )
  const [createDevice] = useMutation(CREATE_DEVICE_MUTATION)

  const save = data => {
    if (data) {
      createDevice({
        variables: {
          data,
        },
        update(cache, mutationResult) {
          const { data: { createDevice: object = {} } = {} } = mutationResult
          cache.modify({
            // id: cache.identify(object),
            fields: {
              getDevices(existingRefs) {
                return [...existingRefs, object]
              },
            },
          })
        },
      }).then(
        () =>
          addToast('New Device created!', {
            appearance: 'success',
            autoDismiss: true,
          }),
        history.push('/app'),
      )
    }
  }
  React.useEffect(() => {
    if (!firstRender.current && getNewEmptyDevice && !data) {
      const transformedData = {
        ...getNewEmptyDevice,
        fields: getNewEmptyDevice.fields
          .slice()
          .sort((a, b) => a.templateField?.order - b.templateField?.order),
      }
      firstRender.current = 1
      setData(transformedData)
    }
  }, [getNewEmptyDevice])

  if (loading) return null
  return (
    <div>
      <DeviceViewEdit data={data} key={firstRender.current} save={save} />
    </div>
  )
}
