import * as React from 'react'

export default ({
  actionFn = () => {},
  modifier = '',
  backgroundColor,
  textColor,
  hoverColor,
  disabled,
}) => (
  <button
    disabled={disabled}
    onClick={actionFn}
    type="button"
    className={`inline-flex items-center p-2 border border-transparent rounded-full shadow-sm ${textColor ||
      'text-white'} ${backgroundColor || 'bg-indigo-600'} hover:${
      disabled ? '' : hoverColor || 'bg-indigo-700'
    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
  >
    {modifier || (
      <svg
        className="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
          clipRule="evenodd"
        />
      </svg>
    )}
  </button>
)
