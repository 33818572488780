import { gql } from '@apollo/client'

export const User = {
  fragments: {
    UserBasicData: gql`
      fragment UserBasicData on User {
        _id
        name
        email
      }
    `,
  },
}

export const Field = {
  fragments: {
    FieldData: gql`
      fragment FieldData on Field {
        _id
        label
        order
        identifier
        type
        values
        allowUserInput
        endpoint
        field
      }
    `,
  },
}
export const Template = {
  fragments: {
    TemplateData: gql`
      fragment TemplateData on Template {
        _id
        name
        fields {
          ...FieldData
        }
      }
      ${Field.fragments.FieldData}
    `,
  },
}

export const Revision = {
  fragments: {
    RevisionData: gql`
      fragment RevisionData on Revision {
        _id
        change
        ticketNumber
        date
      }
    `,
  },
}

export const Seal = {
  fragments: {
    SealData: gql`
      fragment SealData on Seal {
        _id
        active
        value
      }
    `,
  },
}

export const Device = {
  fragments: {
    DeviceListData: gql`
      fragment DeviceListData on Device {
        _id
        name
        lastUpdate
        fields {
          _id
          templateIdentifierNotMatching
        }
      }
    `,
    DeviceData: gql`
      fragment DeviceData on Device {
        _id
        name
        revisions {
          ...RevisionData
        }
        fields {
          _id
          templateIdentifierNotMatching
          value
          historicalValues {
            _id
            user {
              ...UserBasicData
            }
            value
            created_at
          }
          templateField {
            ...FieldData
          }
        }
        seals {
          ...SealData
        }
      }
      ${Field.fragments.FieldData}
      ${Revision.fragments.RevisionData}
      ${Seal.fragments.SealData}
      ${User.fragments.UserBasicData}
    `,
    NewDeviceData: gql`
      fragment NewDeviceData on Device {
        name
        fields {
          value
          templateField {
            ...FieldData
          }
        }
      }
      ${Field.fragments.FieldData}
    `,
  },
}

export const FileTemplates = {
  fragments: {
    FileTemplatesData: gql`
      fragment FileTemplatesData on FileTemplate {
        _id
        content
        configurationType
      }
    `,
  },
}
