import history from '../../history'
export default ({ variables, addToast, mutation, mutationName }) =>
  mutation({
    variables,
    update(cache, mutationResult) {
      const { data: { [mutationName]: object = {} } = {} } = mutationResult
      cache.modify({
        // id: cache.identify(object),
        fields: {
          getDevices(existingRefs, { readField }) {
            return existingRefs.filter(
              er => readField('_id', er) !== object._id,
            )
          },
        },
      })
    },
  }).then(() => {
    addToast('Device deleted!', {
      appearance: 'info',
      autoDismiss: true,
    })
    history.push('/app')
  })
