export default ({
  variables,
  args,
  loading,
  // error,
  addToast,
  mutation,
  mutationName,
}) => {
  if (variables.input && !loading) {
    mutation({
      variables,
      update: (cache, mutationResult) => {
        const { data: { [mutationName]: object } = {} } = mutationResult
        const device = { __typename: 'Device', _id: args.deviceId }
        const isNewRevision = variables.input?.deviceId && !variables.input?._id
        cache.modify({
          id: cache.identify(device),
          fields: {
            revisions(existingRefs = [], { readField }) {
              if (isNewRevision) return [object, ...existingRefs]
              return existingRefs.map(item => {
                if (readField('_id', item) === object._id) return object
                return item
              })
            },
          },
        })
      },
    }).then(() => {
      addToast('Revision saved!', {
        appearance: 'success',
        autoDismiss: true,
      })
    })
  }
}
