import * as React from 'react'
import DatePicker from 'react-date-picker'
// import { formatDate } from '../../utils'

export default ({ revisions = [], deviceId, save = () => {} }) => {
  return (
    <div>
      <RevisionItem save={save} deviceId={deviceId} />
      {revisions.map(revision => (
        <RevisionItem
          key={revision._id}
          {...revision}
          deviceId={deviceId}
          save={save}
        />
      ))}
    </div>
  )
}

const RevisionItem = ({ date, _id, deviceId, change, ticketNumber, save }) => {
  const inputRef = {
    change: React.useRef(),
    ticketNumber: React.useRef(),
  }
  const [inputs, setInputs] = React.useState({
    change,
    ticketNumber,
  })
  const [dateValue, onDateChange] = React.useState(
    (date && new Date(date)) || new Date(),
  )
  const id = {
    change: _id ? _id + 'change' : 'newChange',
    ticket: _id ? _id + 'ticket' : 'newTicket',
  }

  const textAreaAdjust = () => {
    const element = inputRef.change.current
    element.style.height = '1px'
    element.style.height = 2 + element.scrollHeight + 'px'
  }

  const actionButtonLabel = _id ? 'Update' : 'Add'
  const isAdd = !_id
  const disableUpdate = !isAdd && (!dateValue || !inputs.change)
  const disableAdd = isAdd && !inputs.change
  return (
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
      <div className="flex items-center sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
        <label
          htmlFor={_id || 'new'}
          className="w-2/12 block text-sm font-medium text-gray-700 sm:pt-1"
        >
          {/* {formatDate(date || Date.now(), { compact: true })} */}
          <DatePicker onChange={onDateChange} value={dateValue} />
        </label>
        <div className="w-6/12 mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-full mr-6  flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              Change
            </span>
            <textarea
              onKeyUp={textAreaAdjust}
              type="text"
              name={id.change}
              ref={inputRef.change}
              id={id.change}
              value={inputs.change}
              onChange={e => setInputs({ ...inputs, change: e.target.value })}
              rows={Math.round(inputs.change?.length / 40) || 1}
              className="max-w-lg shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md"
            />
          </div>
        </div>
        <div className="w-2/12 mt-1 sm:mt-0 sm:col-span-2">
          <div className="max-w-lg flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
              {inputs.ticketNumber ? (
                <a
                  className="text-blue-500"
                  href={`http://waat.assembla.com/spaces/resistell/tickets/${inputs.ticketNumber}`}
                  target="_blank"
                >
                  Ticket 🔗
                </a>
              ) : (
                'Ticket'
              )}
            </span>
            <input
              type="number"
              name={id.ticket}
              id={id.ticket}
              value={inputs.ticketNumber}
              ref={inputRef.ticketNumber}
              onChange={e =>
                setInputs({
                  ...inputs,
                  ticketNumber: parseInt(e.target.value, 10),
                })
              }
              className="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
            />
          </div>
        </div>
        <div className="ml-4 flex items-center">
          <button
            onClick={() => {
              const isNew = deviceId && !_id
              save({
                ...inputs,
                date: dateValue,
                ...(_id && { _id }),
                ...(deviceId && { deviceId }),
              })

              if (isNew) {
                setInputs({
                  change: undefined,
                  ticketNumber: undefined,
                })
                inputRef.change.current.value = null
                inputRef.ticketNumber.current.value = null
              }
            }}
            type="button"
            disabled={disableUpdate}
            className={`${
              isAdd
                ? disableAdd
                  ? 'text-gray-100 bg-gray-300 cursor-not-allowed'
                  : 'text-gray-100 bg-indigo-700 hover:bg-indigo-900'
                : disableUpdate
                ? 'text-gray-100 bg-gray-300 cursor-not-allowed'
                : 'text-indigo-700 bg-indigo-100 hover:bg-indigo-200'
            } inline-flex items-center px-3.5 py-2.5 border border-transparent text-xs font-medium rounded focus:outline-none`}
          >
            {actionButtonLabel}
          </button>
        </div>
      </div>
    </div>
  )
}
