import * as React from 'react'
import { useQuery } from '@apollo/client'
import { Loading } from 'react-loading-wrapper'
import { GET_DEVICE_BY_ID_QUERY } from '../api'
import {
  DeviceView,
  DeviceViewEdit,
  DeviceViewRevision,
  DeviceViewSeal,
} from '../components'
import useDeviceActions from '../actions/device'
// import useDeviceActions from '../actions/device/_remove'

export default ({ id, tab = {}, isViewOnly } = {}) => {
  const [data, setData] = React.useState()
  const firstRender = React.useRef()
  const { data: { getDeviceById } = {}, loading } = useQuery(
    GET_DEVICE_BY_ID_QUERY,
    {
      variables: {
        id,
      },
      skip: !id,
    },
  )
  // apollo mutations
  const { remove, save, saveRevision, saveSeal } = useDeviceActions()
  React.useEffect(() => {
    if (
      !loading &&
      !getDeviceById?.fields?.every(field =>
        data?.fields?.includes(field?.value),
      )
    ) {
      const transformedData = {
        ...getDeviceById,
        fields: getDeviceById?.fields
          .slice()
          .sort((a, b) => a.templateField?.order - b.templateField?.order),
      }
      firstRender.current = 1
      setData(transformedData)
    }
  }, [getDeviceById])

  if (isViewOnly)
    return (
      <Loading
        loading={loading}
        // Optional props
        color="#059669"
        size={10}
        speed="fast"
        loadingComponent="threeDots"
      >
        <DeviceView data={data} key={firstRender.current} />
      </Loading>
    )
  return (
    <Loading
      loading={loading}
      // Optional props
      color="#059669"
      size={10}
      speed="fast"
      loadingComponent="threeDots"
    >
      <div>
        {tab.id === 'revision' ? (
          <DeviceViewRevision
            revisions={data?.revisions || []}
            deviceId={data._id}
            // key={firstRender.current}
            save={input => {
              saveRevision({ variables: { input }, deviceId: id })
              firstRender.current = firstRender.current + 1
            }}
          />
        ) : tab.id === 'seal' ? (
          <DeviceViewSeal
            isEdit={!!id}
            seals={data?.seals || []}
            deviceId={data._id}
            save={input => {
              saveSeal({ variables: { input }, deviceId: id })
              firstRender.current = firstRender.current + 1
            }}
            remove={() => remove({ variables: { id } })}
          />
        ) : (
          <DeviceViewEdit
            data={data}
            isEdit={!!id}
            key={firstRender.current}
            save={data => {
              save({ variables: { data } })
              firstRender.current = firstRender.current + 1
            }}
            remove={() => remove({ variables: { id } })}
          />
        )}
      </div>
    </Loading>
  )
}
