import * as React from 'react'
import { PageWrapper } from '../components'
import { DeviceListContainer } from '../containers'

export default () => {
  return (
    <PageWrapper title="Device List">
      <DeviceListContainer />
    </PageWrapper>
  )
}
