import React from 'react'
import PropTypes from 'prop-types'
import LogRocket from 'logrocket'
import { useToasts } from 'react-toast-notifications'
import { Home } from './components'
import { Authenticate } from './components/general'
import history from './history'

import './index.css'

let logStarted = false

const App = ({ match, location }) => {
  const { state } = location
  const { addToast } = useToasts()
  React.useEffect(() => {
    if (state?.welcome) {
      addToast('Welcome!', { appearance: 'success', autoDismiss: true })
      history.replace('/') // that's an hack to remove route's state
    }

    if (process.env.NODE_ENV === 'production') {
      if (!logStarted) {
        LogRocket.init('g4ozej/deviceresistellcom', {
          shouldCaptureIP: false,
          shouldDebugLog: false,
        })
        logStarted = true
        console.log('LOGROCKET STARTED')
      }
    }
  }, [])
  return (
    <div className="main">
      <Authenticate>
        <Home match={match} location={location} />
      </Authenticate>
    </div>
  )
}
App.propTypes = {
  match: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
}

export default App
