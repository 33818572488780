const dateOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
export default (date, options = dateOptions) => {
  const { compact, ...formatting } = options
  if (compact) return new Date(date)?.toLocaleDateString('it-IT')
  return new Date(date)?.toLocaleDateString('en-UK', formatting)
}
