import * as React from 'react'
import { PageWrapper } from '../components'
import { DeviceContainer, NewDeviceContainer } from '../containers'

const tabs = {
  edit: {
    id: 'edit',
    label: 'Device Status',
  },
  revision: {
    id: 'revision',
    label: 'Revision track',
  },
  seal: {
    id: 'seal',
    label: 'Active service seals',
  },
}

export default ({ match: { params } = {} } = {}) => {
  const [selectedTab, setSelectedTab] = React.useState(tabs.edit)
  const hasId = params.id
  return (
    <PageWrapper title="Device">
      <div>
        {hasId ? (
          <>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              >
                {Object.values(tabs).map(tab => (
                  <option key={tab.id}>{tab.label}</option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block bg-gray-300 py-4 mb-8 pl-4">
              <nav className="flex space-x-4" aria-label="Tabs">
                {Object.values(tabs).map(tab => (
                  <div
                    key={tab.id}
                    onClick={() => setSelectedTab(tab)}
                    className={`${
                      tab.id === selectedTab.id
                        ? 'bg-indigo-100 text-indigo-700'
                        : 'text-gray-500 hover:text-gray-700 '
                    }  px-3 py-2 font-medium text-sm rounded-md cursor-pointer`}
                  >
                    {tab.label}
                  </div>
                ))}
              </nav>
            </div>
            <DeviceContainer id={params.id} tab={selectedTab} />
          </>
        ) : (
          <NewDeviceContainer />
        )}
      </div>
    </PageWrapper>
  )
}
