import * as React from 'react'

import { useForm, Controller } from 'react-hook-form'
import templateFieldType from '../../settings/enums/_template-field-type.json'
import getEnums from '../../settings/enums'
import { formatDate } from '../../utils'
import { useModalHooks } from '../../hooks'

// fake data generator
// const getItems = count =>
//   Array.from({ length: count }, (v, k) => k).map(k => ({
//     id: `item-${k}`,
//     content: `item ${k}`,
//   }))

const TYPES = getEnums(templateFieldType, 'reference')

export default ({ isEdit, data = { fields: [] }, save = () => {} }) => {
  const { concatenateModal } = useModalHooks()
  const { handleSubmit, register, reset, control, errors } = useForm()
  const renderKey = React.useRef(0)
  const [items, setItems] = React.useState(data.fields)
  const [showActionBar, setShowActionBar] = React.useState(false)
  const updateItem = (updatedItem, options) => {
    const { deleted = false } = options || {}
    let newItems
    if (deleted) {
      newItems = items.filter(
        item => item.identifier !== updatedItem.identifier,
      )
    } else
      newItems = items.map(item => {
        if (item.identifier === updatedItem.identifier) {
          return updatedItem
        }
        return item
      })
    setItems(newItems)
  }
  const onSubmit = mixedFields => {
    const { name, ...fields } = mixedFields || {}
    // console.log({ fields })
    const transformedData = {
      _id: data._id,
      name,
      fields: [
        ...Object.entries(
          Object.entries(fields)?.reduce(
            (obj, [key, value]) => {
              const isCustom =
                key.split('___') &&
                key.split('___')[1] &&
                key.split('___')[1] === 'custom'

              if (isCustom) {
                // override anything set before
                return {
                  ...obj,
                  [key.split('___')[0]]: value,
                }
              }
              if (obj[key]) return obj // do not override

              return {
                ...obj,
                [key]: value,
              }
            },
            { ...fields },
          ),
        )
          .filter(([k, v]) => !!v && !k.includes('___custom'))
          .map(([k, v]) => ({
            value: v,
            templateFieldIdentifier: data.fields.find(
              field => field.templateField?.label === k,
            )?.templateField?.identifier,
          })),
      ],
    }
    // console.log({ transformedData })
    let changedFields = Object.entries(data.fields).filter(([key, value]) => {
      const identifier = data.fields[key].templateField.identifier
      let newData = {}
      transformedData.fields.forEach(field => {
        if (field.templateFieldIdentifier === identifier) {
          newData = field
        } else {
        }
      })
      if (newData.value !== undefined) {
        return data.fields[key].value !== newData.value
      }
    })
    if (changedFields.length > 0) {
      concatenateModal({
        confimationModalTitle: 'Reminder prompt.',
        hideCancelButton: true,
        onConfirm: () => {
          save(transformedData)
        },

        additionalFields: () => {
          return (
            <div>
              <div>You have just updated: </div>
              <div>
                {changedFields.map(changedField => {
                  return (
                    <div key={changedField[1]._id}>
                      <span className="italic">
                        {changedField[1].templateField.label}
                      </span>
                    </div>
                  )
                })}
              </div>
              <br />
              <div>
                Please download an updated set of configuration files and deploy
                it to <strong>{data.name}</strong>
              </div>
            </div>
          )
        },
      })
    } else {
      save(transformedData)
    }
  }
  return (
    <form
      onMouseUp={() => {
        if (!showActionBar) setShowActionBar(true)
      }}
      onSubmit={handleSubmit(onSubmit)}
      className="space-y-8 divide-y divide-gray-200"
    >
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div className="flex justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {isEdit ? 'Edit' : 'Create'}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {isEdit ? "Update the Device's values" : 'Add a new Device'}
              </p>
            </div>
          </div>
          <div>
            <InputSelector
              item={{
                value: data.name,
                templateField: { label: 'name', type: TYPES.TEXT },
              }}
              isEdit={isEdit}
              control={control}
              register={register}
              rules={{ required: true }}
              renderKey={renderKey}
              errors={errors}
            />
          </div>
          {items?.map((item, index) => (
            <InputSelector
              key={index}
              item={item}
              isEdit={isEdit}
              control={control}
              register={register}
              updateItem={updateItem}
              renderKey={renderKey}
              errors={errors}
            />
          ))}
        </div>
        <div
          className={`transition-opacity ${
            showActionBar ? 'opacity-100 ease-in duration-600' : 'opacity-0'
          } w-48 fixed bottom-10 rigth-30 flex justify-center items-center`}
        >
          <div className="w-full  bg-gray-400 flex justify-center py-2 rounded-lg bg-opacity-30">
            <button
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                reset()
                setItems(data.fields)
                renderKey.current = renderKey.current + 1
              }}
            >
              Reset
            </button>
            <button
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  )
}

const History = ({ data, isNotValid }) => {
  const [showItemHistory, setShowItemHistory] = React.useState(false)
  if (isNotValid)
    return (
      <svg
        className="h-6 w-6 text-red-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
    )
  return (
    <div className="relative">
      <div
        className="cursor-pointer group flex items-baseline px-2 py-2 text-base leading-5 font-medium rounded-md"
        aria-current="delete"
        onMouseEnter={e => {
          e.preventDefault()
          e.stopPropagation()
          setShowItemHistory(true)
        }}
        onMouseLeave={e => {
          e.preventDefault()
          e.stopPropagation()
          setShowItemHistory(false)
        }}
      >
        <svg
          className="text-green-300 w-6 h-6 hover:text-green-700"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
      {showItemHistory && (
        <div className="w-96 absolute bg-gray-50 rounded-lg border-gray-500 border-2 p-8 z-10">
          {data?.length ? (
            data?.map(value => (
              <p className="w-full pb-1">
                <span className="w-2/5 mr-4 inline-block text-indigo-700">
                  {`${
                    value.value?.length > 14
                      ? `${value.value?.slice(0, 14)}...`
                      : value.value
                  }`}
                </span>
                <span className="mr-8 w-1/5 inline-block">
                  {value.user?.name}
                </span>
                <span className="w-1/5 inline-block text-gray-700 font-thin">
                  {formatDate(value.created_at, { compact: true })}
                </span>
              </p>
            ))
          ) : (
            <p>No history</p>
          )}
        </div>
      )}
    </div>
  )
}

const InputSelector = ({
  isEdit,
  item,
  register,
  updateItem,
  renderKey,
  control,
  rules = {},
  errors = {},
}) => {
  const [customs, setCustoms] = React.useState({})
  if (!item) return null
  const { type } = item.templateField || {}
  if (type === TYPES.SELECT)
    return (
      <div className="flex items-baseline pb-6">
        {isEdit && (
          <History
            data={item.historicalValues}
            isNotValid={item.templateIdentifierNotMatching}
            key={item.templateField?.label}
          />
        )}
        <Controller
          control={control}
          name={item.templateField?.label}
          render={({ onChange, name, value }) => {
            // console.log(name, value, { customs })
            if (!customs[name] && value === 'custom') {
              setCustoms({
                ...customs,
                [name]: value,
              })
            }
            if (customs[name] && value !== 'custom') {
              const newCustoms = Object.entries(customs).reduce(
                (obj, [key, value]) => {
                  if (key === name) return obj
                  return {
                    ...obj,
                    [key]: value,
                  }
                },
                {},
              )
              setCustoms(newCustoms)
            }
            return (
              <div className="w-full flex flex-wrap sm:border-t sm:border-gray-200">
                <div className="w-full flex items-center sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor={item.templateField?.label}
                    className="block text-sm font-medium text-gray-700 w-1/3"
                  >
                    {item.templateField?.label}
                  </label>
                  <select
                    id={item.templateField?.label}
                    name={item.templateField?.label}
                    className="mt-1 block w-1/3 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    onChange={onChange}
                    defaultValue={
                      !item.templateField?.allowUserInput &&
                      !item.templateField?.values.includes(item.value)
                        ? null
                        : item.value
                    }
                  >
                    <option key="select" value="" disabled selected>
                      Select
                    </option>
                    {item.templateField?.allowUserInput && (
                      <option key="custom" value="custom">
                        Custom value
                      </option>
                    )}
                    {item.templateField?.values.map((value, index) => (
                      <option key={index}>{value}</option>
                    ))}
                  </select>
                </div>

                {(item.templateField?.allowUserInput &&
                  !item.templateField?.values.includes(item.value) &&
                  !value &&
                  !!item.value) ||
                (item.templateField?.allowUserInput && value === 'custom') ? (
                  <div className="w-full">
                    <TextInput
                      item={{
                        ...item,
                        templateField: {
                          ...item.templateField,
                          label: `${item.templateField?.label}___custom`,
                          type: TYPES.TEXT,
                        },
                        value: item.value,
                      }}
                      register={register}
                      isCustomInput
                    />
                  </div>
                ) : null}
              </div>
            )
          }}
        />
      </div>
    )
  return (
    <div className="flex items-baseline pb-6">
      {isEdit && (
        <div
          className={`${
            item.templateField?.label === 'name' ? 'invisible' : ''
          }`}
        >
          <History
            data={item.historicalValues}
            isNotValid={item.templateIdentifierNotMatching}
            key={item.templateField?.label}
          />
        </div>
      )}
      <Controller
        control={control}
        name={item.templateField?.label}
        rules={rules}
        render={({ onChange, name, value }) => {
          return (
            <TextInput
              onChange={onChange}
              item={item}
              name={name}
              register={register}
              control={control}
              hasError={Object.keys(errors).includes(item.templateField?.label)}
            />
          )
        }}
      />
    </div>
  )
}

const TextInput = ({ item, register, onChange, hasError, isCustomInput }) => {
  const isFromAPI = item?.templateField?.type === TYPES.API
  return (
    <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5 w-full">
      <div
        className={`flex items-center ${
          isCustomInput ? 'pt-6' : 'sm:border-t sm:border-gray-200 sm:pt-5'
        }`}
      >
        <label
          htmlFor={item.templateField?.label}
          className="w-1/3 block text-sm font-medium text-gray-700 sm:mt-px"
        >
          {!isCustomInput &&
            item.templateField?.label?.replace(
              /^./,
              item.templateField?.label[0].toUpperCase(),
            )}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2 w-1/3">
          <div
            className={`max-w-lg flex rounded-md shadow-sm ${
              isFromAPI ? 'pointer-events-none' : ''
            }`}
          >
            <input
              disabled={isFromAPI}
              onChange={onChange}
              onKeyPress={e => {
                e.key === 'Enter' && e.preventDefault()
              }}
              key={item._id}
              ref={register({ required: true })}
              defaultValue={item.value}
              type={templateFieldType[item.templateField?.type]}
              name={item.templateField?.label}
              id={item.templateField?.label}
              className={`mr-4 flex-1 block w-full ${
                isFromAPI ? 'h-10 pl-3' : ''
              } ${
                hasError
                  ? 'focus:border-red-500 focus:ring-red-500'
                  : 'focus:ring-indigo-500  focus:border-indigo-500 border-gray-300'
              } min-w-0 rounded-none rounded-l-md rounded-r-md sm:text-sm`}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
